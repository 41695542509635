.mfp-wrap.mpf-new-phones-popup .mfp-container {
    height: 0 !important;
    max-height: 0 !important;
}

#new-phones-modal {
    max-width: 300px;
    margin: 0 auto !important;
    margin-top: 20px !important;
    border: 5px solid #376989;
    border-radius: 5px;
    padding: 10px;
    background-color: #fff;
}
